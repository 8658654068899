<template>
    <v-row>
        <v-col cols="12" lg="8" md="12">
            <v-row>
                <v-col cols="12" md="4">
                    <!-- test -->
                    <crypto-currency-card
                        bg-card-color="primary"
                        heading="Nombre de pluviometre actifs"
                        :sub-heading="carde_quantite_pluviometre"
                        main-icon-text-color="white--text text--lighten-2"
                        main-icon-name="mdi-water-thermometer"
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <crypto-currency-card
                        bg-card-color="info"
                        heading="Hauteur(mm) de pluie enregistrée"
                        :sub-heading="carde_quantite_pluie"
                        main-icon-text-color="white--text text--lighten-2"
                        main-icon-name="mdi-weather-rainy"
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <crypto-currency-card
                        bg-card-color="green"
                        heading="Nombre  de zone de couverture"
                        :sub-heading="carde_quantite_zone"
                        main-icon-text-color="white--text text--lighten-2"
                        main-icon-name="mdi-map-marker-radius"
                    />
                </v-col>
                <v-col cols="12">
                    <base-card>
                        <v-card-title>Point des données relevés</v-card-title>
                        <v-card-text>
                           <v-row>
                            <v-col cols="12" md="4">
                            <v-select
                            outlined
                                v-model="selectmois"
                                :items="listmois"
                                @change="getPointmois()"
                                label="Mois"
                               
                            ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                            <v-select
                            outlined
                                v-model="selectannee"
                                :items="listannee"
                                @change="getPointannee()"
                                label="Année"

                            ></v-select>
                            </v-col>
                           </v-row>
                           <v-data-table
                            :headers="headers"
                            :items="list_point"
                            :search="search"
                            :loading="loading"
                            loading-text="Chargement des données..."
                            :items-per-page="5"
                            class="elevation-1"
                            >
                            <template v-slot:top>
                                <v-toolbar flat>
                                <v-toolbar-title>Point des données relevés</v-toolbar-title>
                                <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                               </v-toolbar>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-icon
                                small
                                class="mr-2"
                                @click="editItem(item)"
                                >
                                mdi-pencil
                                </v-icon>
                                <v-icon
                                small
                                @click="deleteItem(item)"
                                >
                                mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:no-data>
                                <v-btn color="primary" @click="initialize">
                                Réinitialiser
                                </v-btn>
                            </template>
                            </v-data-table>
                        </v-card-text>
                    </base-card>
                </v-col>
                
            </v-row>
        </v-col>
        <v-col cols="12" lg="4" md="12">
            <v-card
    class="mx-auto"
    
  >
    <v-img
      class="white--text align-end"
      height="200px"
      width="550px"
      src="https://agriculture.gouv.bj/img/logo-MAEP.png"
      
    >
      <v-card-title>Minister de l'agriculture de l'elevage et de la peche</v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0">
      Direction
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <div></div>

      <div></div>
    </v-card-text>


  </v-card>
        </v-col>
       
      
    </v-row>
</template>
<script>
import CryptoCurrencyCard from '@/components/card/CryptoCurrencyCard'
import {priceChart, marketChart} from '@/data/cryptoCurrency'
import ChartCard from '@/components/card/ChartCard'
import api from '@/serviceApi/apiService'


export default {
    name: 'Dashbord',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Dashbord'
    },
    components: {
        CryptoCurrencyCard,
        ChartCard
    },
    data() {
        return {
           
            priceChart,
            marketChart,
            tab: null,
           
            order: null,
           
            headers: [
                {
                    text: 'Direction',
                    align: 'start',
                    sortable: false,
                    value: 'direction',
                    width: '350px',divider:true
                },
                { text: 'Année ', value: 'annee',width: '80px',divider:true },
                { text: 'Mois ', value: 'mois',width: '100px',divider:true },
               // { text: 'Nombre total pluviometre de ', value: 'number_total',width: '150px',divider:true },
                { text: 'Nombre pluviometre collecté/ D1', value: 'number_D1' ,width: '5%',divider:true},
                { text: 'Nombre pluviometre collecté/ D2', value: 'number_D2' ,width: '5%',divider:true},
                { text: 'Nombre pluviometre collecté/ D3', value: 'number_D3' ,width: '5%',divider:true},
               
            ],
            carde_quantite_zone:0,
            carde_quantite_pluviometre:0,
            carde_quantite_pluie:0,
            list_point:[],
            selectmois: '',
            selectannee: 0,
            listmois:['JANVIER','FEVRIER','MARS','AVRIL','MAI','JUIN','JUILLET','AOUT','SEPTEMBRE','OCTOBRE','NOVEMBRE','DECEMBRE'],
            listannee:[2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030],

        }
    },
    methods: {
        getColor(lastPrice) {
            if (lastPrice > 4000) return 'danger--text'
            else if (lastPrice > 1000) return 'success--text'
            else return 'priamry--text'
        },
        changeColor(number) {
            if (number > 4) return 'danger white--text'
            else if (number <= 3) return 'success white--text'
            else return 'primary white--text'
        },

        async point_pluviometre(){
            var dates=new Date();
            console.log(dates);
            var mois=dates.getMonth();
            var annee=dates.getFullYear();
            console.log(mois);
            console.log(annee);

            this.selectannee=annee;
            this.selectmois=this.traitement_mois(mois);
          
            let fd = new FormData();
            fd.append('mois',this.selectmois);
            fd.append('annee',annee);
            const data = await api.createUpdatedata('admin-backoffice/statistique-dashbord', fd);
            this.list_point=data;
        },

      

        async point_card_donne(){
           let fd=new FormData();
            const data=await api.createUpdatedata('admin-backoffice/statistique-dashbord-card',fd); 
             this.carde_quantite_zone=data.zone_pluie;
             this.carde_quantite_pluviometre=data.nombre_pluviometre;    
             this.carde_quantite_pluie=data.quantite_pluie;
        },

        traitement_mois(index){
            
            return index==0?'JANVIER':index==1?'FEVRIER':index==2?'MARS':index==3?'AVRIL':index==4?'MAI':index==5?'JUIN':index==6?'JUILLET':index==7?'AOUT':index==8?'SEPTEMBRE':index==9?'OCTOBRE':index==10?'NOVEMBRE':index==11?'DECEMBRE':'';
        },

        async getPointannee(){
            let fd = new FormData();
            fd.append('mois',this.selectmois);
            fd.append('annee',this.selectannee);
            const data = await api.createUpdatedata('admin-backoffice/statistique-dashbord', fd);
            this.list_point=data;
        },
        async getPointmois(){
            let fd = new FormData();
            fd.append('mois',this.selectmois);
            fd.append('annee',this.selectannee);
            const data = await api.createUpdatedata('admin-backoffice/statistique-dashbord', fd);
            this.list_point=data;
        },
        
    },
    mounted() {
        this.point_card_donne();
        this.point_pluviometre();
    },

}
</script>
